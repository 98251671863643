body {
  font-family: "Montserrat" !important;
}
a {
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
}
/* ==========================
-- LOGO
========================== */
.logo-default {
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 10px 0;
}
.logo-default img {
  width: 170px;
}
.expertise-logo {
  min-height: 93px;
}
.expertise-logo img {
  min-width: 200px;
}

.size-icon-1 {
  max-width: 70px;
}

.size-icon-2 {
  width: 140px;
}

.size-icon-3{
  width: 207px;
}

.img-tanks {
  max-width: 500px;
}
.user-initial {
  text-transform: capitalize;
  width: 60px;
  /* height: 10px; */
  border-radius: 100px;
  font-size: 30px;
  padding: 5px;
  text-align: center;
  color: #fff;
  background: linear-gradient(360deg, #B5983E 25%, #FBDB5B 100%);
}

/* ==========================
-- LIST
========================== */

.item-list .icon-item-list img{
  width: 43px;
}

.border-bottom-1 {
  border-bottom: 1px solid #ddd
}

.box-item {
  /* background: linear-gradient(19deg, #e2e2e2 0%, #ffffff 100%);
  padding: 20px; */
  border-radius: 20px;
}
/* ==========================
-- TEXTS
========================== */
.text-bold {
  font-weight: bold !important;
}

.title-ex-big {
  font-size: 45px;
  font-weight: bold;
}

.img-icon-title {
  width: 50px;
  margin-left: 10px;
}

.box-title {
  display: inline-block;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd
}

.text-1 {
  font-size: 12px;
}
.text-2 {
  font-size: 14px;
}
.text-3 {
  font-size: 16px;
}
.text-4 {
  font-size: 18px;
}

.title {
  font-weight: bold;
  font-size: 26px;
}

.title-2 {
  font-weight: bold;
  font-size: 20px;
}
.title-3 {
  font-weight: bold;
  font-size: 16px;
}
.title-link {
  font-weight: bold;
  font-size: 14px;
}

.title-4 {
  font-weight: bold;
  font-size: 32px;
}

.title-5 {
  font-weight: bold;
  font-size: 40px;
}

.title-6 {
  font-weight: bold;
  font-size: 50px;
}
/* ==========================
-- ELEMENTS
========================== */
.shadow-one {
  -webkit-box-shadow: -1px 3px 11px -4px rgba(0,0,0,0.48);
  box-shadow: -1px 3px 11px -4px rgba(0,0,0,0.48);
  background-color: #fff;
}

.shadow-two {
  -webkit-box-shadow: -1px 3px 10px -4px rgba(0,0,0,0.48);
  box-shadow: -1px 3px 10px -4px rgba(0,0,0,0.48);
}

.shadow-three{
  box-shadow: 0px 0px 8px 0px #00000029;
}

.inp {
  box-shadow: 6px -2px 24px -10px rgba(0,0,0,0.74);
  -webkit-box-shadow: 6px -2px 24px -10px rgba(0,0,0,0.74);
  -moz-box-shadow: 6px -2px 24px -10px rgba(0,0,0,0.74);
  border: 1px solid #ddd;
}

.input-file {
  position: relative;
}

.input-file input{
  position: absolute;
  z-index: 1;
  opacity: 0;
  top: 0;
  cursor: pointer;
}

.input-file button{
  position: absolute;
  top: 0;
  z-index: 0;
}

.title-shadow-one {
  background-color: #FE6A88;
  width: 100%;
  display: inline-block;
  padding: 25px;
  color: #fff;
}
.title-2-shadow-one {
  width: 100%;
  display: inline-block;
  padding: 10px;
  color: #fff;
}

.title-shadow-one-top {
  position: absolute;
  top: 0;
  left: 0;
}
/* ==========================
-- CARDS
========================== */
.card-one {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.card-header-one {
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-body-one {
  padding: 10px;
  font-size: 26px;
  text-align: center;
  font-weight: bold;
}

.card-footer-one {
  background-color: #eee;
  padding: 10px;
  text-align: center;
  font-size: 13px;
  color: #555;
}

.card-custom {
  /* border: 2px solid #bbb; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* ==========================
-- CORES DAS EMPRESAS
========================== */
.color-br5-1 {
  color: #325fa9 !important;
}

.bg-color-br5-1 {
  background-color: #325fa9 !important;
}

.link-hover-br5-1:hover:after {
  box-shadow: 0 0 0 5px #325fa9;
}

.color-exp-1 {
  color: #454545 !important;
}

.bg-color-exp-1 {
  background: linear-gradient(360deg, #000000 25%, #454545 100%);
}

.link-hover-exp-1:hover:after {
  box-shadow: 0 0 0 5px #454545;
}

.color-top-1 {
  color: #d8020a !important;
}

.bg-color-top-1 {
  background: linear-gradient(360deg, #a40a10 25%, #d8020a 100%);
}

.link-hover-top-1:hover:after {
  box-shadow: 0 0 0 5px #d8020a;
}

/* ==========================
-- COLORS AND BACKGROUNDS
========================== */

.yr {
  border-right: 1px solid #aaa;
}
.xb {
  border-bottom: 1px solid #aaa;
}

.radius-1 {
  border-radius: 10px;
}

.bg-color-none {
  background: none;
}
.color-white {
  color: #FFFFFF !important;
}

.color-black{
  color: #000 !important;
}

.bg-color-black {
  background-color: #000000 !important;
}

.color-1 {
  color: #B5983E !important;
}
.bg-color-1 {
  /* background-color: #B5983E !important; */
  background: linear-gradient(360deg, #B5983E 25%, #FBDB5B 100%);
}

.color-2 {
  color: #4C4849 !important;
}
.bg-color-2 {
  background-color: #4C4849 !important;
}

.color-3 {
  color: #B42844 !important;
}
.bg-color-3 {
  background-color: #B42844 !important;
}

.color-4 {
  color: #737071 !important;
}
.bg-color-4 {
  background-color: #737071 !important;
}

.color-5 {
  color: #b1b1b1 !important;
}
.bg-color-5 {
  background-color: #b1b1b1 !important;
}

.color-6 {
  color: #ccc !important;
}
.bg-color-6 {
  background-color: #ccc !important;
}

.color-7{
  color: #EEEEEE !important;
}

.bg-color-7{
  background-color: #EEEEEE !important;
}

.color-8{
  color:  #D9ECE3;
}

.bg-color-8{
  background-color:  #D9ECE3;
}

.color-9{
  color: #8F8F8F;
}

.bg-color-9{
  background: #8F8F8F;
}


.color-success {
  color: #7ADCB1 !important;
}
.bg-color-success {
  background-color: #7ADCB1 !important;
}

.color-danger{
  color:#ed1a3b;
}

.bg-color-danger{
  background-color:#ed1a3b;
}




.bg-color-high {
  /* background-color: #B5983E !important; */
  background: linear-gradient(360deg, #000000 25%, #454545 100%);
}

.bg-color-middle {
  /* background-color: #B5983E !important; */
  background: linear-gradient(360deg, #B29D47 25%, #FBDB5B 100%);
}

.bg-color-bottom {
  /* background-color: #B5983E !important; */
  background: linear-gradient(360deg, #999999 25%, #DBDBDB 100%);
}

/* .link-one {
  -webkit-transition: background 0.2s 0s linear;
  -moz-transition: background 0.2s 0s linear;
  -o-transition: background 0.2s 0s linear;
  transition: background 0.2s 0s linear;
} */
.cursor-pointer {
  cursor: pointer !important;
}

.link-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.line-link-one {
  height: 8px;
  width: 100%;
  background-color: #333;
  opacity: 0.4;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.line-link-one-fill {
  height: 100% !important;
  background-color: #000;;
  opacity: 0.5;
  transition:all 0.5s;
}
.link-one-clicked {
  border: 8px solid #333 !important;
}
.link-one {
  cursor: pointer;
  /* border: 1px solid #fff; */
  position: relative;
  z-index: 0;
}
.link-one h5 {
  position: relative;
  z-index: 100;
}
.link-one h5:hover {
  color: #fff;
}

.link-one:after{
  position:absolute;
  /* border-radius: 50%; */
  content:"";
  z-index:10;/* depends on your need change to 1 if want to overlap the div on hover*/
  top:-10px;
  bottom:-10px;
  left:-10px;
  right:-10px;
  transition:all 0.3s;
}

.link-hover-1:hover:after{
  box-shadow:0 0 0 5px #B5983E;
}

.link-hover-3:hover:after{
  box-shadow:0 0 0 5px #912339;
}

.link-hover-4:hover:after{
  box-shadow:0 0 0 5px #5c5759;
}

.link-hover-5:hover:after{
  box-shadow:0 0 0 5px #bdbdbd;
}

.link-hover-success:hover:after {
  box-shadow:0 0 0 5px #00bc8c;
}

/* .footer-w {
  position: relative;
  bottom: 0;
  background: linear-gradient(360deg, #000000 25%, #454545 100%);
} */

/* ==========================
-- LOGIN
========================== */
.login-block {
  width:100%;
  padding: 50px 0;
  background: linear-gradient(360deg, #000000 25%, #454545 100%);
  height: 100vh;
}

.logo-login {
  text-align: center;
  width: 100% !important;
  display: block;
}
.icon-login {
  text-align: center;
  width: 100% !important;
  display: block;
}
/* .icon-login img {
  max-width: 64px !important;
} */
.logo-login img {
  width: 100%;
  /* min-width: 400px; */
}
.container-login {
  /* background:#ffffff; */
  background: linear-gradient(360deg, #353535 25%, #656565 100%);
  border-radius: 10px;
  width: 550px;
  /* height: 450px; */
}
.login-sec{padding: 50px 30px;
  position:relative;
}
.login-sec h2{
  margin-bottom:30px;
  font-weight:800;
  font-size:30px;
  color: #0069c0;
}
.login-sec h2:after{
  content:" ";
  width:100px;
  height:5px;
  background:#6ec6ff;
  display:block;
  margin-top:20px;
  border-radius:3px;
  margin-left:auto;
  margin-right:auto
}
.btn-login{
  background: #0069c0;
  color:#fff;
  font-weight:600;
}

@media only screen and (max-width: 760px) {
  .container-login {
    width: 100% !important;
    /* height: 450px; */
  }
  .login-block .shadow-one {
    box-shadow: none;
  }
}

/* ==========================
-- INPUTS
========================== */
.label {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000;
}

.input {
  height: 52px;
  border: 1px solid #8f8f8f;
  & > placeholder {
    color: #343434;
  }
}

.input-disabled{
  background: #E6E6E6;
  cursor: not-allowed;
}

.input-container{
  display: flex;
  align-items: flex-end;
  width:100%;
  margin-bottom: 16px;
}

.input-container>div{
  width:100%;
}

@media only screen and (max-width: 600px) {
  .input-container {
    flex-direction: column;
    margin-bottom: 0;
    align-items: flex-start;
  }

  .input-container>div{
    margin-bottom: 16px;
  }
}

.pin-code-container{
  display: flex;
  gap:10px;
  justify-content: center;
}

.input-pin-code{
  width: 40px;
  height: 52px;
  text-align:center;
  border: 1px solid #8F8F8F;
  color: #8F8F8F;
}


/* ==========================
-- TIMELINE
========================== */
/* body {
  margin-top: 20px;
} */

.text-5 {
  font-size: 22px !important;
}
.icon-step {
  text-align: center;
  /* width: 50px; */
  display: inline;
  /* margin-top: -10px; */
  padding: 15px;
  background-color: #FE6A88;
  border-radius: 50px;
}
.timeline-steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap
}

.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem
}

@media (min-width:768px) {
  .timeline-steps .timeline-step:not(:last-child):after {
    content: "";
    display: block;
    border-top: .25rem dotted #888;
    width: 3.46rem;
    position: absolute;
    left: 7.5rem;
    top: .3125rem
  }

  .timeline-steps .timeline-step:not(:first-child):before {
    content: "";
    display: block;
    border-top: .25rem dotted #888;
    width: 3.8125rem;
    position: absolute;
    right: 7.5rem;
    top: .3125rem
  }
}

.timeline-steps .timeline-content {
  width: 10rem;
  text-align: center
}

.timeline-steps .timeline-content .inner-circle {
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #FE6A88
}

.timeline-steps .timeline-content .inner-circle:before {
  content: "";
  background-color: #FE6A88;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 6.25rem;
  opacity: .5
}

@media only screen and (max-width: 768px) {
  .login-block {
    padding: 0px 0;
    /* background-color: lightblue !important; */
  }
  .container-login {
    background: transparent;
  }
  .btn-quote {
    margin-bottom: 8px;
    width: 100%;
  }
  .yr {
    border-right: transparent;
  }
  /* [class*="col-"] {
    width: 100%;
  } */
  /* bod */
  /* background: linear-gradient(360deg, #fff 25%, #fff 100%); */
}

/* ==========================
-- BUTTONS
========================== */

.button-active {
  width: 100%;
  max-width: 246px;
  border-radius: 5px;
  border: none;
  background: #343434;
  text-align: start;
  font-weight: 600;
  font-size: 14px;
}

.button{
  width: 100%;
  max-width: 246px;
  border-radius: 5px;
  border: none;
  background: #FFF;
  text-align: start;
  font-weight: 600;
  font-size: 14px;
  color: black;
  cursor: pointer;
}

.button:focus{
  outline: none;
}

.button-password{
  border:none;
  background: white;
  position: absolute;
  top:45px;
  right:10px;
  cursor:pointer;

}

.button-password:focus{
outline:none;
}

.min-h-80{
  min-height: 80vh;
}

.button-first-access{
  border: 1px solid #000000;
  padding: 10px;
  background: transparent;
  width: 143px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  cursor:pointer;
}

.button-fist-access-back{
  border:none;
  padding: 10px;
  background: transparent;
  width: 143px;
  cursor:pointer;
}

.button-sign{
  color: #0056FF;
  border: 1px solid #075BFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  background: transparent;
  width: 177px;
  padding: 10px 0;
  display: flex;
  gap:10px;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}

.button-sign:hover{
  filter: brightness(0.8);
}

.button-width{
  width: 177px;
  padding: 10px 0;
  margin-bottom: 1rem;
  cursor: pointer;
}

.button-width:hover{
  filter: brightness(0.8);
}

.button-disabled{
  background-color:gray;
  cursor: not-allowed;
}

.border-black{
  border: 1px solid black;
  cursor: pointer;
}

.pointer{
  cursor: pointer;
}

/* ==========================
-- PROFILE SETTINGS
========================== */


.page-container {
  padding: 22px 75px 34px 75px;
}

.title-settings{
  font-weight: bold;
  font-size: 40px;
}

@media only screen and (max-width: 600px) {
  .title-settings{
    font-size: 32px;
  }

  .page-container {
    padding: 4px 16px;
  }
}

.text-underline{
  text-decoration: underline;
}


.contract-url{
  /* makes the content that has more then 2 lines to be hidden and appears '...' */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}



/*==========================
-- PRODUTOS E TREINAMENTO
========================== */

.container-video {
  flex-direction: row;
}

.video-menor {
  width: 25%;
}
.container-video-text {
  width: 75%;
}

.container-img{
  height: 220px;
}

.container-banner{
  height:257px;
}



@media (max-width:1265px) {
  .container-video {
    flex-direction: column;
    align-items: center;
  }

  .container-video-text {
    width: 100%;
  }

  .video-menor {
    width: 100%;
  }
}

/* ==========================
-- LICENSE INFORMATION
========================== */

.edit-license-button{
  width: 169px;
  height: 42px;
}

.group{
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  padding: 0 10px 14px 10px;
}

/* ==========================
-- SELLER
========================== */

.border-black{
  border: 1px solid black;
}

.border-gray{
  border: 1px solid #D9D9D9;
}

.ant-checkbox-group-item {
  padding: 5px 0;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black;
  border-color: black;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-checked::after, .ant-input:hover,
.ant-input:focus{
  border-color: black;
}

.ant-input:focus{
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
}


/* ==========================
-- COMISSION REPORT
========================== */

.btn-clear-filters{
  max-width:130px;
}

.btn-add-filters{
  max-width:140px;
}
