.span-whatsapp {
    font-size: 16px;
    margin-left: 10px;
    margin-top: 3px;
    display: inline-block;
    transition: 0.95s all ease;
  }
  
  .span-whatsapp:hover {
    display: inline !important;
    transition: 0.95s all ease;
  }
  
  .wrapper-whatsapp {
    bottom: 100px;
    right: 20px;
    background-color: #25d366;
    color: #fff;
    border-radius: 10px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    padding: 10px;
    cursor: pointer;
    transform: scale(1);
    transition: 0.95s all ease;
    display: flex;
  }
  
  .wrapper-whatsapp:hover {
    transform: scale(1.1);
    color: #fff;
    font-size: 18px;
  }
  